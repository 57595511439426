








import { defineComponent, onBeforeUnmount, onMounted, ref, Ref } from '@vue/composition-api';
let tLoop: number;

export default defineComponent({
	setup() {
		const Dom1: Ref<HTMLCanvasElement | null> = ref(null);
		const Dom2: Ref<HTMLCanvasElement | null> = ref(null);
		const Dom3: Ref<HTMLCanvasElement | null> = ref(null);
		/*
		const canvasInit = () => {
			const canvas = Dom.value as HTMLCanvasElement;
			const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
			const w = (canvas.width = canvas.parentElement?.offsetWidth || 1345);
			const h = (canvas.height = canvas.parentElement?.offsetWidth || 330);
			const hue = 217;
			const stars: any[] = [];
			let count = 0;
			const maxStars = 500;

			// Cache gradient
			const canvas2 = document.createElement('canvas') as HTMLCanvasElement;
			const ctx2 = canvas2.getContext('2d') as CanvasRenderingContext2D;
			canvas2.width = 100;
			canvas2.height = 100;
			const half = canvas2.width / 2;
			const gradient2 = ctx2.createRadialGradient(half, half, 0, half, half, half);
			gradient2.addColorStop(0.025, '#fff');
			//gradient2.addColorStop(0.1, 'hsl(' + hue + ', 61%, 33%)');
			//gradient2.addColorStop(0.25, 'hsl(' + hue + ', 64%, 6%)');
			gradient2.addColorStop(0.1, 'hsl(' + hue + ', 61%, 6%)');
			gradient2.addColorStop(0.25, 'hsl(' + hue + ', 64%, 1%)');
			gradient2.addColorStop(1, 'transparent');

			ctx2.fillStyle = gradient2;
			ctx2.beginPath();
			ctx2.arc(half, half, half, 0, Math.PI * 2);
			ctx2.fill();

			// End cache

			function random(min: number, max = 0) {
				if (arguments.length < 2) {
					max = min;
					min = 0;
				}

				if (min > max) {
					let hold = max;
					max = min;
					min = hold;
				}

				return Math.floor(Math.random() * (max - min + 1)) + min;
			}

			var Star: any = function (): void {
				this.orbitRadius = random(w / 2 - 50);
				this.radius = random(100, this.orbitRadius) / 10;
				this.orbitX = w / 2;
				this.orbitY = h / 2;
				this.timePassed = random(0, maxStars);
				this.speed = random(this.orbitRadius) / 100000;
				this.alpha = random(2, 10) / 10;

				count++;
				stars[count] = this;
			};

			Star.prototype.draw = function () {
				var x = Math.sin(this.timePassed + 1) * this.orbitRadius + this.orbitX,
					y = (Math.cos(this.timePassed) * this.orbitRadius) / 2 + this.orbitY,
					twinkle = random(10);

				if (twinkle === 1 && this.alpha > 0) {
					this.alpha -= 0.05;
				} else if (twinkle === 2 && this.alpha < 1) {
					this.alpha += 0.05;
				}

				ctx.globalAlpha = this.alpha;
				ctx.drawImage(canvas2, x - this.radius / 2, y - this.radius / 2, this.radius, this.radius);
				this.timePassed += this.speed;
			};

			for (var i = 0; i < maxStars; i++) {
				new Star();
			}

			function animation() {
				//ctx.globalCompositeOperation = 'source-over';
				//ctx.globalAlpha = 1;
				//ctx.fillStyle = 'hsla(' + hue + ', 64%, 6%, 1)';
				//ctx.fillRect(0, 0, w, h);

				//ctx.globalCompositeOperation = 'lighter';
				for (var i = 1, l = stars.length; i < l; i++) {
					stars[i].draw();
				}

				tLoop = window.requestAnimationFrame(animation);
			}

			animation();
		};
        */
		const canvasInit = () => {
			const meteorCanvas = Dom1.value as HTMLCanvasElement;
			const starCanvas = Dom2.value as HTMLCanvasElement;
			const moonCanvas = Dom3.value as HTMLCanvasElement;
			const meteors: any[] = [],
				stars: any[] = [];

			meteorCanvas.width = document.body.clientWidth; //meteorCanvas.parentElement?.offsetWidth || 1345; //document.body.clientWidth;
			meteorCanvas.height = document.body.clientHeight; //meteorCanvas.parentElement?.offsetHeight || 330; //document.body.clientHeight;
			starCanvas.width = meteorCanvas.width; //document.body.clientWidth;
			starCanvas.height = meteorCanvas.height / 3; //document.body.clientHeight / 3;
			moonCanvas.width = meteorCanvas.width; //document.body.clientWidth;
			moonCanvas.height = meteorCanvas.height / 3; //document.body.clientHeight / 3;
			//console.log(meteorCanvas.width, meteorCanvas.height);
			const meteorCtx = meteorCanvas.getContext('2d') as CanvasRenderingContext2D;
			const starCtx = starCanvas.getContext('2d') as CanvasRenderingContext2D;
			const moonCtx = moonCanvas.getContext('2d') as CanvasRenderingContext2D;
			let moon: any;
			class Moon {
				x: number;
				y: number;
				ctx: CanvasRenderingContext2D;
				r: number;
				constructor(x: number, y: number, ctx: CanvasRenderingContext2D, r = 15) {
					this.x = x;
					this.y = y;
					this.ctx = ctx;
					this.r = r;
				}
				draw() {
					this.ctx.fillStyle = 'rgba(255,255,255,0.6)';
					this.ctx.shadowBlur = this.r + 5; //光晕半径
					this.ctx.shadowColor = '#fff'; // 光晕颜色
					this.ctx.arc(this.x, this.y, this.r, 0, Math.PI * 2);
					this.ctx.fill();
				}
			}
			class Star extends Moon {
				constructor(x: number, y: number, ctx: CanvasRenderingContext2D, r: number) {
					super(x, y, ctx, r);
				}
				draw() {
					this.ctx.fillStyle = 'rgba(255,255,255,0.8)';
					this.ctx.beginPath();
					this.ctx.arc(this.x, this.y, this.r, 0, 2 * Math.PI);
					this.ctx.closePath();
					this.ctx.fill();
				}
				move() {
					this.x += 0.08;
					if (this.x > meteorCanvas.width) {
						this.x = 0;
					}
					this.draw();
				}
			}
			class Meteor extends Star {
				angle: any;
				constructor(x: number, y: number, ctx: CanvasRenderingContext2D, r: number, angle: any) {
					super(x, y, ctx, r);
					this.angle = angle;
				}

				draw() {
					this.ctx.fillStyle = '#ffffff';
					this.ctx.rotate(this.angle);
					this.ctx.translate(100, -meteorCanvas.height / 1.5);
					this.ctx.beginPath();
					this.ctx.arc(this.x, this.y, this.r, 0, 2 * Math.PI);
					this.ctx.closePath();
					this.ctx.fill();
					this.ctx.translate(-100, meteorCanvas.height / 1.5);
					this.ctx.rotate(-this.angle);
				}
				move() {
					this.x += 4;
					this.y += 1;
					if (this.x > meteorCanvas.width) {
						this.x = Math.random() * 5;
						this.y = -2 * meteorCanvas.height + Math.random() * meteorCanvas.height * 3;
					}
					this.draw();
				}
			}

			init();
			animate();

			function init() {
				/*
				for (var i = 0; i < 4; i++) {
					meteors[i] = new Meteor(
						Math.random() * meteorCanvas.width,
						-2 * meteorCanvas.height + Math.random() * meteorCanvas.height * 3,
						meteorCtx,
						Math.floor(Math.random() * 2) + 1.5,
						Math.PI / 7
					);
					meteors[i].draw();
				}
                */
				for (let i = 0; i < 60; i++) {
					stars[i] = new Star(Math.random() * starCanvas.width, Math.random() * starCanvas.height, starCtx, Math.random());
					stars[i].draw();
				}
				//moon = new Moon(moonCanvas.width - 50, 50, moonCtx);
				//moon.draw();
			}
			function animate() {
				starCtx.clearRect(0, 0, starCanvas.width, starCanvas.height);
				meteorCtx.fillStyle = `rgba(0, 0, 0, 0)`;
				meteorCtx.fillRect(0, 0, meteorCanvas.width, meteorCanvas.height);
				for (let meteor of meteors) meteor.move();
				for (let star of stars) star.move();
				requestAnimationFrame(animate);
			}
			function recover() {
				for (let meteor of meteors) meteor = null;
				for (let star of stars) star = null;
				moon = null;
			}
			window.onresize = function () {
				meteorCanvas.width = document.body.clientWidth; //meteorCanvas.parentElement?.offsetWidth || 1345; //document.body.clientWidth;
				meteorCanvas.height = document.body.clientHeight; // meteorCanvas.parentElement?.offsetHeight || 330; //document.body.clientHeight;
				starCanvas.width = meteorCanvas.width; //document.body.clientWidth;
				starCanvas.height = meteorCanvas.height / 3; //document.body.clientHeight / 3;
				moonCanvas.width = meteorCanvas.width; //document.body.clientWidth;
				moonCanvas.height = meteorCanvas.height / 3; //document.body.clientHeight / 3;
				recover();
				init();
			};
		};
		onMounted(canvasInit);
		onBeforeUnmount(() => cancelAnimationFrame(tLoop));
		return { Dom1, Dom2, Dom3 };
	},
});
